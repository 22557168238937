<template>
  <button
      type="button"
      @click="handleSignUp"
      class="select-none inline-flex items-center gap-x-1.5 rounded-l-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
  >
    Sign Up
  </button>

</template>

<script lang="ts" setup>
import { useAuth0 } from "@auth0/auth0-vue";

const { loginWithRedirect } = useAuth0();

const handleSignUp = () => {
  loginWithRedirect({
    appState: {
      target: '/auth/onboarding',
    },
    authorizationParams: {
      screen_hint: "signup",
      display: "page",
      max_age: 2 * 60 * 60, // 2 hours
      ui_locales: "en",
    }
  });
};
</script>