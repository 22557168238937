<template>
  <button
      type="button"
      @click="handleLogout"
      class="select-none inline-flex items-center gap-x-1.5 rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
  >
    Sign Out
    <ArrowRightCircleIcon class="-mr-0.5 h-5 w-5" aria-hidden="true" />
  </button>
</template>

<script lang="ts" setup>
import { useAuth0 } from "@auth0/auth0-vue";
import {ArrowRightCircleIcon} from "@heroicons/vue/24/outline";
import {useAuthStore} from "@/stores/auth";

const { logout } = useAuth0();

const handleLogout = () => {
  const authStore = useAuthStore();
  authStore.logOut();

  logout({
      logoutParams: {
        returnTo: import.meta.env.VITE_AUTH0_LOGOUT_URL,
      }
    });
};
</script>