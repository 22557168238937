import './assets/main.css'

import * as Sentry from "@sentry/vue";
import { createApp } from 'vue'
import { createPinia } from 'pinia'
import { createAuth0 } from '@auth0/auth0-vue';

import App from './App.vue'
import router from './router'

const app = createApp(App)

app.use(createPinia())
app.use(router)

app.use(
    createAuth0({
        domain: import.meta.env.VITE_AUTH0_DOMAIN,
        clientId: import.meta.env.VITE_AUTH0_CLIENT_ID,
        authorizationParams: {
            screen_hint: "login",
            audience: import.meta.env.VITE_AUTH0_AUDIENCE,
            redirect_uri: import.meta.env.VITE_AUTH0_CALLBACK_URL,
        }
    })
);

// If production
if (import.meta.env.PROD) {
    Sentry.init({
        app,
        dsn: import.meta.env.VITE_SENTRY_DSN,
        integrations: [
            new Sentry.BrowserTracing({
                // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
                tracePropagationTargets: [
                    "https://m2text.com",
                    "https://www.m2text.com",
                    "https://api.m2text.com"
                ],
                routingInstrumentation: Sentry.vueRouterInstrumentation(router),
            }),
            new Sentry.Replay(),
        ],
        // Performance Monitoring
        // TODO: Change from 1.0 to 0.1 later when we have more traffic
        tracesSampleRate: 0.1,
        // Session Replay
        // TODO: Change from 1.0 to 0.1 later when we have more traffic
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
        release: import.meta.env.VITE_APP_VERSION,
        environment: import.meta.env.VITE_APP_ENV
    });
}

app.mount('#app')
