<template>
  <TransitionRoot as="template" :show="open && !useAuthStore().isUserAuthenticated()">
    <Dialog class="relative z-10" @close="close">
      <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
        <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
      </TransitionChild>

      <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
            <DialogPanel class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
              <div>
                <div class="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                  🎉
                </div>
                <div class="mt-3 text-center sm:mt-5">
                  <DialogTitle as="h3" class="text-base font-semibold leading-6 text-gray-900">Try for free!</DialogTitle>
                  <div class="mt-2">
                    <p class="text-sm text-gray-500">
                      Sign up for a free account and get started with our platform.
                      <br />
                      Or simply continue with Google in the next page!
                      <br /><br />
                      (we use well-trusted <b>Auth0</b> for user management)
                      <br />
                      (we process and store data on <b>AWS cloud in EU</b>)
                    </p>
                  </div>
                </div>
              </div>
              <div class="mt-6 grid grid-flow-row-dense grid-cols-2 gap-3">
                <SignupButton />
                <LoginButton />
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup lang="ts">
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import LoginButton from "@/components/buttons/login-button.vue";
import SignupButton from "@/components/buttons/signup-button.vue";
import {useAuthStore} from "@/stores/auth";

const emit = defineEmits<{
  (e: 'close'): void;
}>();

defineProps<{
  open: boolean,
}>();

const close = () => {
  emit('close');
};

</script>