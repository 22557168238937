import { defineStore } from 'pinia'
import { useAuth0 } from "@auth0/auth0-vue";
import ProfileService from "@/services/profile-service";
import * as Sentry from "@sentry/browser";
import type { User } from "@/views/Profile.vue";

export const useAuthStore = defineStore('auth', () => {
  const { isAuthenticated } = useAuth0();

  function isUserAuthenticated(): boolean {
    const token = getToken();
    if (token) {
        return true;
    }
    return isAuthenticated.value;
  }

  function setToken(token: string) {
    getStorage().setItem("auth_token", token);
    // Set Sentry user on production
    if (import.meta.env.PROD) {
      ProfileService.get().then((response) => {
        const user: User = response.data.data;
        if (user.id && user.email) {
          Sentry.setUser({
            id: user.id,
            email: user.email,
            ip_address: "{{auto}}",
          });
        }
      });
    }
  }

  function getToken(): string|null {
    return getStorage().getItem("auth_token");
  }

  function getSubFromToken(): string|null {
    const token = getToken();
    if (token) {
        const payload = JSON.parse(atob(token.split('.')[1]));
        return payload.sub;
    }
    return null;
  }

  function logOut() {
    getStorage().removeItem("auth_token");
    Sentry.setUser(null);
  }

  function getStorage(): Storage {
    // Use session storage, if local storage is not available
    if (typeof localStorage === 'undefined') {
        return sessionStorage;
    }
    return localStorage;
  }

  return {
    isUserAuthenticated,
    setToken,
    getToken,
    logOut
  }
})
