<template>
  <div class="bg-white py-24 sm:py-32">
    <div class="mx-auto max-w-7xl px-6 lg:px-8">
      <div class="mx-auto grid max-w-lg grid-cols-4 items-center gap-x-4 gap-y-2 lg:mx-0 lg:max-w-none md:grid-cols-6 lg:grid-cols-8 2xl:grid-cols-11">
        <img class="max-h-12 w-full object-contain" src="https://upload.wikimedia.org/wikipedia/commons/thumb/0/0b/English_language.svg/320px-English_language.svg.png" alt="English" width="158" height="48" />
        <img class="max-h-12 w-full object-contain" src="https://upload.wikimedia.org/wikipedia/commons/2/20/Flag_of_the_Netherlands.svg" alt="Dutch" width="158" height="48" />
        <img class="hidden md:block max-h-12 w-full object-contain" src="https://upload.wikimedia.org/wikipedia/commons/b/ba/Flag_of_Germany.svg" alt="German" width="158" height="48" />
        <img class="max-h-12 w-full object-contain" src="https://upload.wikimedia.org/wikipedia/commons/c/c3/Flag_of_France.svg" alt="French" width="158" height="48" />
        <img class="hidden md:block max-h-12 w-full object-contain" src="https://upload.wikimedia.org/wikipedia/commons/4/49/Flag_of_Spanish_language_%28ES-MX%29.svg" alt="Spanish" width="158" height="48" />
        <img class="hidden lg:block max-h-12 w-full object-contain" src="https://upload.wikimedia.org/wikipedia/commons/0/03/Flag_of_Italy.svg" alt="Italian" width="158" height="48" />
        <img class="max-h-12 w-full object-contain" src="https://upload.wikimedia.org/wikipedia/commons/f/fa/Flag_of_the_People%27s_Republic_of_China.svg" alt="Chinese" width="158" height="48" />
        <img class="hidden 2xl:block max-h-12 w-full object-contain" src="https://upload.wikimedia.org/wikipedia/commons/9/9e/Flag_of_Japan.svg" alt="Japan" width="158" height="48" />
        <img class="hidden 2xl:block max-h-12 w-full object-contain" src="https://upload.wikimedia.org/wikipedia/commons/c/ca/Flag_of_Iran.svg" alt="Persian" width="158" height="48" />
        <img class="hidden 2xl:block max-h-12 w-full object-contain" src="https://upload.wikimedia.org/wikipedia/commons/2/2b/Flag_of_the_Arab_League.svg" alt="Arabic" width="158" height="48" />
        <img class="hidden lg:block max-h-12 w-full object-contain" src="https://upload.wikimedia.org/wikipedia/commons/9/9c/Flag_of_Denmark.svg" alt="Danish" width="158" height="48" />
        <div class="w-full text-center text-sm font-semibold">English</div>
        <div class="w-full text-center text-sm font-semibold">Dutch</div>
        <div class="hidden md:block w-full text-center text-sm font-semibold">German</div>
        <div class="w-full text-center text-sm font-semibold">French</div>
        <div class="hidden md:block w-full text-center text-sm font-semibold">Spanish</div>
        <div class="hidden lg:block w-full text-center text-sm font-semibold">Italian</div>
        <div class="w-full text-center text-sm font-semibold">Chinese</div>
        <div class="hidden 2xl:block w-full text-center text-sm font-semibold">Japanese</div>
        <div class="hidden 2xl:block w-full text-center text-sm font-semibold">Persian<br />(Farsi)</div>
        <div class="hidden 2xl:block w-full text-center text-sm font-semibold">Arabic</div>
        <div class="hidden lg:block w-full text-center text-sm font-semibold">Danish</div>
      </div>
    </div>
  </div>
</template>
