import { defineStore } from 'pinia'
import { ref } from "vue";
import type { UiNotification } from "@/components/notification.vue";

export const useNotificationStore = defineStore('notification', () => {
  const notifications = ref<UiNotification[]>([]);

  function appendNotification(notification: UiNotification) {
    notification.visible = true;
    notifications.value.push(notification);
    if (notification.type !== 'success') {
      // Play a ding sound
      const audio = new Audio('/media/alert-message-pop.mp3');
      audio.play().then(r => {
          console.log('Notification played');
      });
    }
  }

  function closeNotification(id: string) {
    // Find notification by id
    const notification = notifications.value.find((n) => n.id === id);
    if (!notification) return;
    // Set visible to false
    notification.visible = false;
  }

  return {
    notifications,
    appendNotification,
    closeNotification
    }
})
