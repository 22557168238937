import http from "@/services/http-common";

class ProfileService {
  get(): Promise<any> {
    return http.get("/authenticated/profile");
  }
  patch(data: { key: string, value: string|boolean }[] ): Promise<any> {
    return http.patch("/authenticated/profile", data);
  }
}

export default new ProfileService();
