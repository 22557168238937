<template>
  <!-- Top section -->
  <header class="bg-gray-200 sticky top-0 z-50 border-b-2 border-b-white border-opacity-30">
    <!-- Mobile menu, show/hide based on menu state. -->
    <Disclosure
        as="nav"
        class="bg-gray-800"
        v-slot="{ open }"
    >
      <div class="-mr-2 flex sm:hidden">
        <!-- Mobile menu button -->
        <DisclosureButton class="relative inline-flex items-center justify-center rounded-t-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
          <span class="absolute -inset-0.5" />
          <span class="sr-only">Open main menu</span>
          <Bars3Icon v-if="!open" class="block h-16 w-16" aria-hidden="true" />
          <XMarkIcon v-else class="block h-16 w-16" aria-hidden="true" />
        </DisclosureButton>
        <div class="flex-grow"></div>
<!--        <div class="flex-shrink-0 flex items-center px-12 hidden">-->
<!--          <span class="inline-block mx-2 text-gray-100 font-semibold text-sm">Support:</span><a class="text-base font-semibold text-gray-50" href="tel:+31 (xxx) xxx-xxxx">+31 (xxx) xxx-xxxx</a>-->
<!--        </div>-->
      </div>
      <DisclosurePanel class="md:hidden" v-slot="{ close }">
        <Menu as="div" class="grid font-semibold font-sm text-gray-100 border-t-2 border-gray-600">
          <router-link @click="close()" class="select-none" v-if="!isAuthenticated" to="/"><a class="hover:text-gray-400 hover:bg-gray-600 flex py-8 justify-center items-center border-b-2 border-gray-600">Home</a></router-link>
          <router-link @click="close()" class="select-none" v-if="isAuthenticated" to="/transcribe"><a class="hover:text-gray-400 hover:bg-gray-600 flex py-8 justify-center items-center border-b-2 border-gray-600">Transcribe</a></router-link>
          <router-link @click="close()" class="select-none" v-if="!isAuthenticated" to="/pricing"><a class="hover:text-gray-400 hover:bg-gray-600 flex py-8 justify-center items-center border-b-2 border-gray-600">Pricing</a></router-link>
          <router-link @click="close()" class="select-none" v-if="isAuthenticated" to="/profile"><a class="hover:text-gray-400 hover:bg-gray-600 flex py-8 justify-center items-center border-b-2 border-gray-600">Profile</a></router-link>
          <router-link @click="close()" class="select-none" to="/contact-us"><a class="hover:text-gray-400 hover:bg-gray-600 flex py-8 justify-center items-center border-b-2 border-gray-600">Contact Us</a></router-link>
          <router-link @click="close()" class="select-none" to="/faq"><a class="hover:text-gray-400 hover:bg-gray-600 flex py-8 justify-center items-center border-b-2 border-gray-600">FAQ</a></router-link>
          <router-link @click="close()" class="select-none" to="/security"><a class="hover:text-gray-400 hover:bg-gray-600 flex py-8 justify-center items-center border-b-2 border-gray-600">Security</a></router-link>
          <router-link @click="close()" class="select-none" to="/terms-of-service"><a class="hover:text-gray-400 hover:bg-gray-600 flex py-8 justify-center items-center border-b-2 border-gray-600">Terms of service</a></router-link>
          <router-link @click="close()" class="select-none" to="/privacy-policy"><a class="hover:text-gray-400 hover:bg-gray-600 flex py-8 justify-center items-center border-b-2 border-gray-600">Privacy Policy</a></router-link>
          <router-link @click="close()" class="select-none" to="/cookie-policy"><a class="hover:text-gray-400 hover:bg-gray-600 flex py-8 justify-center items-center border-b-2 border-gray-600">Cookie Policy</a></router-link>

        </Menu>
      </DisclosurePanel>
    </Disclosure>
    <!-- container makes it fixed after 2xl -->
    <div class="2xl:container mx-auto flex items-center justify-start space-x-4 md:space-x-12 pr-8">
      <router-link to="/"><img src="/assets/img/logo/logo-2.png" alt="logo" class="h-20"></router-link>
      <nav class="hidden sm:block space-x-2 md:space-x-4">
        <router-link v-if="!isAuthenticated" to="/"><a class="select-none font-semibold font-sm text-gray-700 hover:text-gray-900">Home</a></router-link>
        <router-link v-if="isAuthenticated" to="/transcribe"><a class="select-none font-semibold font-sm text-gray-700 hover:text-gray-900">Transcribe</a></router-link>
        <span class="select-none text-gray-400">|</span>
        <router-link v-if="!isAuthenticated" to="/pricing"><a class="select-none font-semibold font-sm text-gray-700 hover:text-gray-900">Pricing</a></router-link>
        <router-link v-if="isAuthenticated" to="/profile"><a class="select-none font-semibold font-sm text-gray-700 hover:text-gray-900">Profile</a></router-link>
        <span class="select-none text-gray-400">|</span>
        <router-link to="/contact-us"><a class="select-none font-semibold font-sm text-gray-700 hover:text-gray-900">Contact Us</a></router-link>
      </nav>
      <nav class="flex-grow"></nav> <!-- Pushes account to the right -->
      <nav>
        <div class="nav-bar__buttons">
          <template v-if="!isAuthenticated">
            <SignupButton />
            <span class="text-gray-400 px-0.5"></span>
            <LoginButton />
          </template>
          <template v-if="isAuthenticated">
            <LogoutButton />
          </template>
        </div>
      </nav>
    </div>
  </header>

  <!-- Middle section -->
  <main class="container mx-auto flex-grow mb-56">
    <RouterView />
  </main>

  <!-- Bottom section -->
  <Disclosure
      as="footer"
      class="bg-gray-200 fixed bottom-0 left-0 right-0 h-30 px-8"
      v-slot="{ open }"
      :key="'id-'+isCookieMenuOpened"
      :defaultOpen="isCookieMenuOpened"
  >
    <div class="flex items-center justify-between">
      <DisclosurePanel
          class="
            fixed
            right-0
            px-6
            pb-8
            pt-4
            w-full
            h-[13rem]
            mb-[17rem]
            sm:w-[48rem]
            xs:h-[12rem]
            xs:mb-[16rem]
            sm:h-[10rem]
            sm:mb-[14.4rem]
            text-base
            bg-gray-800
            text-gray-100
            border-t-2 border-gray-700
            md:rounded-tl-md md:border-t-2 md:border-l-2 md:border-gray-400
            text-justify
          "
          v-slot="{ close }">

        <div class="pb-2 text-md tracking-widest font-semibold">
          <span class="cursor-pointer select-none" @click="close(); acceptCookie();">
            [
            <span class="text-lg hover:text-yellow-400">
            <XMarkIcon class="h-6 w-6 inline-block" aria-hidden="true" />
            </span>
            ]
          </span>
        </div>
        Using cookies and analyzing IP addresses allow us to deliver and improve our web content and to provide you
        with a better experience.
        That means this website uses cookies and collects IP address for the purposes mentioned in the
        <router-link class="link-for-dark-bg" to="/cookie-policy">cookie policy</router-link>.


      </DisclosurePanel>
      <nav class="flex-grow-0 space-x-4 py-4">
        <router-link class="select-none cursor-pointer hidden md:inline" to="/about-us"><a>About</a></router-link>
        <span class="select-none cursor-default text-gray-400 hidden md:inline">|</span>
        <router-link class="select-none cursor-pointer hidden md:inline" to="/terms-of-service"><a>Terms of Service</a></router-link>
        <span class="select-none cursor-default text-gray-400 hidden md:inline">|</span>
        <router-link class="select-none cursor-pointer hidden md:inline" to="/privacy-policy"><a>Privacy Policy</a></router-link>
        <span class="select-none cursor-default text-gray-400 hidden md:inline">|</span>
        <router-link class="select-none cursor-pointer hidden md:inline" to="/cookie-policy"><a>Cookie Policy</a></router-link>
        <span class="select-none cursor-default text-gray-400 hidden md:inline">|</span>
        <router-link class="select-none cursor-pointer hidden md:inline" to="/faq"><a>FAQ</a></router-link>
        <span class="select-none cursor-default text-gray-400 hidden md:inline">|</span>
        <router-link class="select-none cursor-pointer hidden md:inline" to="/security"><a>Security</a></router-link>
      </nav>
      <div class="flex-grow"></div>
      <DisclosureButton as="nav" class="flex-grow-0 place-content-end  bg-gray-800 px-4 py-4 cursor-pointer select-none">
        <div
            class="relative inline-flex items-center justify-center pt-2 text-gray-100 font-semibold"
        >
          <ChevronDoubleDownIcon v-if="open" class="h-6 w-6" aria-hidden="true" @click="acceptCookie()" />
          <ChevronDoubleUpIcon v-else class="h-6 w-6" aria-hidden="true" />
        </div>
      </DisclosureButton>
    </div>
  </Disclosure>

  <!-- Notifications -->
  <Notification></Notification>

</template>

<script lang="ts" setup>
import { useAuthStore } from "@/stores/auth";
import { onMounted, ref } from "vue";
import { RouterView } from "vue-router";
import { useAuth0 } from "@auth0/auth0-vue";
import LoginButton from "@/components/buttons/login-button.vue";
import LogoutButton from "@/components/buttons/logout-button.vue";
import SignupButton from "@/components/buttons/signup-button.vue";

import { Disclosure, DisclosureButton, DisclosurePanel, Menu } from '@headlessui/vue'
import { Bars3Icon, XMarkIcon, ChevronDoubleUpIcon, ChevronDoubleDownIcon } from '@heroicons/vue/24/outline'
import Notification from "@/components/notification.vue";

const authStore = useAuthStore();
const { getAccessTokenSilently } = useAuth0();
const isAuthenticated = ref(false);
const isCookieMenuOpened = ref<boolean>(false);

const acceptCookie = () => {
  console.log('Cookie accepted!');
  localStorage.setItem("cookieAccepted", "true");
}

onMounted(async () => {
  let accessToken: string = '';
  try {
    accessToken = await getAccessTokenSilently();
  } catch (e) {
    console.log("Error: ", e);
  }
  if (accessToken) {
    authStore.setToken(accessToken);
  }
  isAuthenticated.value = authStore.isUserAuthenticated();
  console.log("isAuthenticated: ", isAuthenticated.value);

  // A workaround (key props) is used to force re-render the Disclosure component
  // when isCookieMenuOpened is changed
  // https://github.com/tailwindlabs/headlessui/issues/770#issuecomment-917663690
  if (
      isAuthenticated.value ||
      localStorage.getItem("cookieAccepted") === "true"
  ) {
    console.log("Cookie is already accepted or user is authenticated.");
    isCookieMenuOpened.value = false;
  } else {
    console.log("Cookie is NOT accepted yet!");
    isCookieMenuOpened.value = true;
  }
});
</script>
