<template>
  <!-- Global notification live region, render this permanently at the end of the document -->
  <div
      aria-live="assertive"
      class="pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6 mb-16 sm:mt-20"
  >
    <div class="flex w-full flex-col items-center space-y-4 sm:items-end">
      <!-- Notification panel, dynamically insert this into the live region when it needs to be displayed -->
      <transition
          v-for="notification in notifications"
          enter-active-class="transform ease-out duration-300 transition"
          enter-from-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
          enter-to-class="translate-y-0 opacity-100 sm:translate-x-0"
          leave-active-class="transition ease-in duration-100"
          leave-from-class="opacity-100"
          leave-to-class="opacity-0">
        <div
            v-if="notification.visible"
            class="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5"
            :class="bgColor(notification.type)"
        >
          <div class="p-4">
            <div class="flex items-start">
              <div class="flex-shrink-0">
                <CheckCircleIcon
                    v-if="notification.type == 'success'"
                    class="mt-1 h-12 w-12 text-green-600 font-bold"
                    aria-hidden="true"
                />
                <ExclamationCircleIcon
                    v-if="notification.type == 'error'"
                    class="mt-1 h-12 w-12 text-red-600 font-bold"
                    aria-hidden="true"
                />
                <ExclamationTriangleIcon
                    v-if="notification.type == 'warning'"
                    class="mt-1 h-12 w-12 text-yello-600 font-bold"
                    aria-hidden="true"
                />
                <InboxIcon
                    v-if="notification.type == 'none'"
                    class="mt-1 h-12 w-12 text-white font-bold"
                    aria-hidden="true"
                />
              </div>
              <div class="ml-3 w-0 flex-1 pt-0.5">
                <p class="text-sm font-medium text-gray-900" v-html="notification.title"></p>
                <p class="mt-1 text-sm text-gray-500 text-justify tracking-tight" v-html="notification.description"></p>
              </div>
              <div class="ml-4 flex flex-shrink-0">
                <button
                    type="button"
                    @click="closeNotification(notification.id)"
                    class="inline-flex rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    :class="bgColor(notification.type)"
                >
                  <span class="sr-only">Close</span>
                  <XMarkIcon class="h-5 w-5" aria-hidden="true" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { InboxIcon, ExclamationTriangleIcon, ExclamationCircleIcon, CheckCircleIcon } from '@heroicons/vue/24/outline'
import { XMarkIcon } from '@heroicons/vue/20/solid'
import {useNotificationStore} from "@/stores/notification";
import {onMounted, watch} from "vue";

export type UiNotification = {
  id: string
  title: string
  description: string
  type: 'success' | 'error' | 'warning' | 'none'
  visible?: boolean
  autoClose?: boolean
}

const bgColor = (type: string) => {
  switch (type) {
    case 'success':
      return 'bg-green-50';
    case 'error':
      return 'bg-red-50';
    case 'warning':
      return 'bg-yellow-50';
    case 'none':
      return 'bg-white';
    default:
      return 'bg-white';
  }
}

const closeNotification = (id: string) => {
  const notificationStore = useNotificationStore();
  notificationStore.closeNotification(id);
}

const notificationStore = useNotificationStore();
const notifications = notificationStore.notifications;

// When the component is mounted, watch the notifications array
onMounted(() => {
  watch(notifications, (newNotifications) => {
    newNotifications.forEach((notification: UiNotification) => {
      if (notification.autoClose) {
        setTimeout(() => {
          closeNotification(notification.id);
        }, 10000);  // Close after 10 seconds, if autoClose is true and the notification is still visible
      }
    });
  }, { deep: true });
});

</script>