<template>
  <button
      type="button"
      @click="handleLogin"
      class="select-none inline-flex items-center gap-x-1.5 rounded-r-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
  >
    <span class="hidden lg:inline-block">Already a user? - </span>Sign In
    <ArrowRightCircleIcon class="-mr-0.5 h-5 w-5" aria-hidden="true" />
  </button>
</template>

<script lang="ts" setup>
import { useAuth0 } from "@auth0/auth0-vue";
import { ArrowRightCircleIcon } from '@heroicons/vue/24/outline';

const { loginWithRedirect } = useAuth0();

const handleLogin = () => {
  loginWithRedirect({
    appState: {
      target: '/auth/onboarding',
    },
    authorizationParams: {
      screen_hint: "login",
      display: "page",
      max_age: 2 * 60 * 60, // 2 hours
      ui_locales: "en",
    }
  });
};
</script>