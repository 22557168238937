import { createRouter, createWebHistory } from 'vue-router'
import type { RouteRecordRaw } from 'vue-router'
import { authGuard } from "@auth0/auth0-vue";
import HomeView from '../views/HomeView.vue'
import Page404 from "@/views/Page404.vue";
import ProfileService from "@/services/profile-service";
import type {User} from "@/views/Profile.vue";

const routes: Readonly<RouteRecordRaw[]> = [
    {
        path: '/',
        name: 'home',
        component: HomeView
    },
    {
        path: '/pricing',
        name: 'pricing',
        // route level code-splitting
        // this generates a separate chunk (About.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import('@/views/PricingView.vue')
    },
    {
        path: '/about-us',
        name: 'about-us',
        component: () => import('@/views/AboutUsView.vue')
    },
    {
        path: '/contact-us',
        name: 'contact-us',
        component: () => import('@/views/ContactUsView.vue')
    },
    {
        path: '/terms-of-service',
        name: 'terms-of-service',
        component: () => import('@/views/TermsOfServiceView.vue')
    },
    {
        path: '/privacy-policy',
        name: 'privacy-policy',
        component: () => import('@/views/PrivacyPolicyView.vue')
    },
    {
        path: '/cookie-policy',
        name: 'cookie-policy',
        component: () => import('@/views/CookiePolicyView.vue')
    },
    {
        path: '/security',
        name: 'security',
        component: () => import('@/views/SecurityView.vue')
    },
    {
        path: '/faq',
        name: 'faq',
        component: () => import('@/views/Faq.vue')
    },
    {
        path: '/supported-languages',
        name: 'supported-languages',
        component: () => import('@/views/SupportedLanguages.vue')
    },
    {
        path: '/landing-pages/journalist',
        name: 'lp-journalist',
        component: () => import('@/views/landing-pages/Journalist.vue')
    },
    {
        path: '/landing-pages/student',
        name: 'lp-student',
        component: () => import('@/views/landing-pages/Student.vue')
    },
    {
        path: '/auth/onboarding',
        name: 'auth-onboarding',
        component: () => import('@/views/auth/onboarding.vue')
    },
    {
        path: '/profile',
        name: 'profile',
        component: () => import('@/views/Profile.vue'),
        beforeEnter: authGuard,
    },
    {
        path: '/transcribe',
        name: 'transcribe',
        component: () => import('@/views/Transcribe.vue'),
        beforeEnter: authGuard,
    },
    {
        path: '/transcribe/:id',
        name: 'transcribe-one',
        component: () => import('@/views/TranscribeOne.vue'),
    },
    {path: '/404', component: Page404},
    // {path: '/:catchAll(.*)', redirect: '/404'},
];

const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes: routes
});

router.beforeEach((to, from, next) => {
    // If user is on onboarding page, redirect to transcribe page if user has already accepted terms of service and privacy policy
    if (to.path === '/auth/onboarding') {
        ProfileService.get().then((response: any) => {
            let user: User;
            console.log(response.data.data);
            user = response.data.data;
            if (
                user &&
                user.isAcceptedTermsOfService &&
                user.isAcceptedPrivacyPolicy &&
                user.isAcceptedCookiePolicy
            ) {
                console.log('redirecting to transcribe');
                router
                    .push({name: 'transcribe'})
                    .then((r: any) => {
                        console.log(r);
                    });
            }
        });
    }
    // Go to next route
    next();
});

export default router
