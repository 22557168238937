import axios, {AxiosError} from "axios";
import type {AxiosResponse} from "axios";
import type {AxiosInstance} from "axios";

const apiClient: AxiosInstance = axios.create({
    baseURL: import.meta.env.VITE_API_SERVER_URL,
    headers: {
        "Content-type": "application/json",
    },
});

apiClient.interceptors.request.use((config) => {
    const token = localStorage.getItem("auth_token");
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    // If x-retry-count is not set, initialize it to 0
    if (!config.headers['x-retry-count']) {
        config.headers['x-retry-count'] = 0;
    }
    return config;
});

// Wait 1 second and retry failed request up to 5 times if http code is 401
const maxRetryCount = 5;
apiClient.interceptors.response.use(
    (response: AxiosResponse) => response, // on successful response
    async (error: AxiosError) => { // on error
        if (error.config) {
            // + is used to convert string to number
            const retryCount = +error.config.headers['x-retry-count'] || 0;

            if (error.response?.status === 401 && retryCount < maxRetryCount) {
                // If error was a 401, and we haven't reached max retry count, wait 1 second and try again
                await new Promise(resolve => setTimeout(resolve, 1000));
                error.config.headers['x-retry-count'] = retryCount + 1;
                return apiClient.request(error.config);
            }
        }

        // If error was not a 401 or if maximum retries have been attempted, reject the error
        return Promise.reject(error);
    }
);

export default apiClient;